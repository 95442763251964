import React, { useMemo, useState } from "react";

import { TextField, MenuItem } from "@mui/material";
import styled from "@emotion/styled";
import { useSearchParams } from "react-router-dom";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DateTime } from "luxon";
import { LoadingButton } from "@mui/lab";
import roomsData from "../roomsData";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 90vh;
  gap: 10px;
  margin: 20px;
`;

const BookingNewScreen = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const roomId = searchParams.get("id");
  const dateFrom = searchParams.get("dateFrom");
  const dateTo = searchParams.get("dateTo");

  const room = useMemo(() => {
    return roomsData.find((room) => room.id.toString() === roomId);
  }, [roomsData, roomId]);

  const [price, setPrice] = useState<string>("" + room?.price);
  const [firstPayment, setFirstPayment] = useState<string>();
  const [name, setName] = useState<string>();
  const [dni, setDNI] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [phone, setPhone] = useState<string>();

  const handleDateFrom = (newValue: DateTime | null) => {
    searchParams.set("dateFrom", newValue!.toISODate());
    setSearchParams(searchParams);
  };

  const handleDateTo = (newValue: DateTime | null) => {
    searchParams.set("dateTo", newValue!.toISODate());
    setSearchParams(searchParams);
  };

  return (
    <Container>
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <DatePicker
          label="Fecha Ingreso"
          inputFormat="dd/MM/yyyy"
          value={dateFrom}
          onChange={handleDateFrom}
          renderInput={(params) => <TextField {...params} />}
        />

        <DatePicker
          label="Fecha Egreso"
          inputFormat="dd/MM/yyyy"
          value={dateTo}
          onChange={handleDateTo}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
      <TextField
        id="outlined-select-currency"
        select
        label="Select"
        defaultValue={roomId}
        helperText="Elegi el dormitorio"
      >
        {roomsData.map((room) => (
          <MenuItem key={room.id} value={room.id}>
            {room.name}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        value={price}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          setPrice(event.target.value)
        }
        id="outlined-basic"
        label="Precio"
        variant="outlined"
      />

      <TextField
        value={firstPayment}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          setFirstPayment(event.target.value)
        }
        id="outlined-basic"
        label="Seña"
        variant="outlined"
      />

      <TextField
        value={name}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          setName(event.target.value)
        }
        id="outlined-basic"
        label="Nombre"
        variant="outlined"
      />
      <TextField
        value={dni}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          setDNI(event.target.value)
        }
        id="outlined-basic"
        label="DNI"
        variant="outlined"
      />
      <TextField
        value={email}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          setEmail(event.target.value)
        }
        id="outlined-basic"
        label="Email"
        variant="outlined"
      />
      <TextField
        value={phone}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          setPhone(event.target.value)
        }
        id="outlined-basic"
        label="Telefono"
        variant="outlined"
      />

      <LoadingButton variant="contained" size="small" onClick={() => {}}>
        Reservar
      </LoadingButton>

      <br />
    </Container>
  );
};

export default BookingNewScreen;
