import React from "react";

import styled from "@emotion/styled";

import { Card, Grid } from "@mui/material";
import RoomGrid from "../components/rooms/RoomGrid";

const Container = styled.div`
  margin: 20px;
`;

const RoomScreen = () => {
  return (
    <Container>
      <RoomGrid />
    </Container>
  );
};

export default RoomScreen;
