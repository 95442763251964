import React from "react";
import "./App.css";

import { Amplify } from "aws-amplify";

import Router from "./router/Router";

import awsExports from "./config/aws-exports";
Amplify.configure(awsExports);

function App() {
  return (
    <div className="App">
      <Router />
    </div>
  );
}

export default App;
