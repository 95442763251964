import React from "react";
import styled from "@emotion/styled";
import { Box, CircularProgress } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DateTime } from "luxon";
import TextField from "@mui/material/TextField";
import RoomGrid from "../components/rooms/RoomGrid";
import RoomType from "../types/RoomType";
import queryString from "query-string";
import { useNavigate } from "react-router-dom";

const Container = styled(Box)`
  margin: 20px;
  gap: 10px;
`;

const DatePickerContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
`;

const RoomContainer = styled(Box)`
  margin-top: 20px;
  gap: 10px;
  display: flex;
  flex-direction: column;
`;

const AvailabilityScreen = () => {
  const navigate = useNavigate();

  const [dateFrom, setDateFrom] = React.useState<DateTime>(DateTime.now());
  const [dateTo, setDateTo] = React.useState<DateTime>(
    DateTime.now().plus({ days: 7 })
  );

  const handleDateFrom = (newValue: DateTime | null) => {
    setDateFrom(newValue!);
  };

  const handleDateTo = (newValue: DateTime | null) => {
    setDateTo(newValue!);
  };

  const handleReservationClick = (room: RoomType) => {
    navigate(
      "/bookings/new?" +
        queryString.stringify({
          id: room.id,
          dateFrom: dateFrom.toISODate(),
          dateTo: dateTo.toISODate(),
        })
    );
  };

  return (
    <Container>
      <DatePickerContainer>
        <LocalizationProvider dateAdapter={AdapterLuxon}>
          <DatePicker
            label="Fecha Ingreso"
            inputFormat="dd/MM/yyyy"
            value={dateFrom}
            onChange={handleDateFrom}
            renderInput={(params) => <TextField {...params} />}
          />

          <DatePicker
            label="Fecha Egreso"
            inputFormat="dd/MM/yyyy"
            value={dateTo}
            onChange={handleDateTo}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>

        <CircularProgress size={20} />
      </DatePickerContainer>

      <RoomContainer>
        <RoomGrid handleReservationClick={handleReservationClick} />
      </RoomContainer>
    </Container>
  );
};

export default AvailabilityScreen;
