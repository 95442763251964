import RoomType from "./types/RoomType";

const roomsData: RoomType[] = [
  {
    name: "Duplex 1",
    image: "",
    price: 20000,
    id: 1,
  },
  {
    name: "Duplex 2",
    image: "",
    price: 20000,
    id: 2,
  },
  {
    name: "Duplex 3",
    image: "",
    price: 20000,
    id: 3,
  },
  {
    name: "Duplex 4",
    image: "",
    price: 20000,
    id: 4,
  },
  {
    name: "Duplex 5",
    image: "",
    price: 20000,
    id: 5,
  },
];

export default roomsData;
