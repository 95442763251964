import React, { useState } from "react";

import { Auth } from "aws-amplify";

import { Card, TextField, Box, Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import styled from "@emotion/styled";

const MyCard = styled(Card)`
  display: flex;
  flex-direction: column;
  width: 250px;
  padding: 20px;
`;

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: gray;
`;

const LoginScreen = () => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("aguss.vazquez@gmail.com");
  const [password, setPassword] = useState<string>("Pescados.12345");
  const [user, setUser] = useState();

  const handleSignIn = (email: string, password: string) => {
    setLoading(true);
    Auth.signIn(email, password)
      .then((user) => {
        setUser(user);
        setLoading(false);

        Auth.currentSession().then((res) => {
          let accessToken = res.getAccessToken();
          let jwt = accessToken.getJwtToken();

          //You can print them to see the full objects
          console.log(`myAccessToken: ${JSON.stringify(accessToken)}`);
          console.log(`myJwt: ${jwt}`);
        });
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleForgotPassword = () => {
    Auth.forgotPassword(email);
  };

  return (
    <Container>
      <MyCard>
        <TextField
          value={email}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setEmail(event.target.value)
          }
          required
          type={"email"}
          label="Email"
          variant="outlined"
        />
        <br />
        <TextField
          value={password}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setPassword(event.target.value)
          }
          required
          type="password"
          label="Password"
          variant="outlined"
        />

        <br />

        <LoadingButton
          loading={isLoading}
          variant="contained"
          size="small"
          onClick={() => handleSignIn(email, password)}
        >
          Iniciar sesion
        </LoadingButton>

        <br />

        <Button onClick={handleForgotPassword}>
          ¿Olvidaste tu contraseña?
        </Button>
      </MyCard>
    </Container>
  );
};

export default LoginScreen;
