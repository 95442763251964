import React from "react";

import { Card } from "@mui/material";
import styled from "@emotion/styled";

const MyCard = styled(Card)`
  display: flex;
  flex-direction: column;
  max-width: 30vw;
`;

const Container = styled.div`
  display: flex;
  flex: 1;
  height: 90vh;
`;

const UserScreen = () => {
  return <Container>User screen</Container>;
};

export default UserScreen;
