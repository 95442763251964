import React, { useEffect } from "react";
import {
  createBrowserRouter,
  Route,
  BrowserRouter,
  Routes,
} from "react-router-dom";
import AppBar from "../components/menu/AppBar";

import UserScreen from "../screens/UserScreen";
import Sidebar from "../components/menu/Sidebar";
import AvailabilityScreen from "../screens/AvailabilityScreen";
import BookingScreen from "../screens/BookingScreen";

import { Box, CircularProgress } from "@mui/material";
import RoomScreen from "../screens/RoomScreen";
import BookingNewScreen from "../screens/BookingNewScreen";
import LoginScreen from "../screens/auth/LoginScreen";
import { Auth, Hub } from "aws-amplify";

const drawerWidth = 240;

const Router = () => {
  const [mobileOpen, setMobileOpen] = React.useState<boolean>(false);
  const [isLoggedIn, setLoggedIn] = React.useState<boolean>(false);
  const [isLoading, setLoading] = React.useState<boolean>(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    setLoading(true);
    Auth.currentAuthenticatedUser()
      .then((response) => {
        console.log("asdsd");
        setLoading(false);
        setLoggedIn(true);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    Hub.listen("auth", (data) => {
      switch (data.payload.event) {
        case "signIn":
          setLoggedIn(true);
          break;
        case "signOut":
          setLoggedIn(false);
          break;
      }
    });
  }, []);

  if (isLoading) return <CircularProgress />;
  if (isLoggedIn === false) return <LoginScreen />;

  return (
    <BrowserRouter>
      <Box sx={{ display: "flex" }}>
        <AppBar
          drawerWidth={drawerWidth}
          handleDrawerToggle={handleDrawerToggle}
        />
        <Sidebar
          drawerWidth={drawerWidth}
          mobileOpen={mobileOpen}
          handleDrawerToggle={handleDrawerToggle}
        />

        <Box
          component="main"
          sx={{
            flexGrow: 1,
            marginTop: "60px",
            width: { sm: `calc(100% - ${drawerWidth}px)` },
          }}
        >
          <Routes>
            <Route path="availability" element={<AvailabilityScreen />} />
            <Route path="bookings" element={<BookingScreen />} />
            <Route path="bookings/new" element={<BookingNewScreen />} />
            <Route path="rooms" element={<RoomScreen />} />
            <Route path="users" element={<UserScreen />} />
          </Routes>
        </Box>
      </Box>
    </BrowserRouter>
  );
};

export default Router;
