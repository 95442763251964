import React from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import {
  CalendarToday,
  Event,
  House,
  Logout,
  Person,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";

interface IProps {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
  drawerWidth: number;
  mobileOpen: boolean;
  handleDrawerToggle: () => void;
}

interface MenuItemProps {
  title: string;
  Icon: React.ReactNode;
  path?: string;
  onClick?: () => void;
}
const MenuItem = ({
  title,
  Icon,
  onClick = () => {},
  path = undefined,
}: MenuItemProps) => {
  const navigate = useNavigate();
  const handleOnClick = () => {
    if (path) navigate(path);
    else onClick();
  };

  return (
    <ListItem key={title} disablePadding onClick={handleOnClick}>
      <ListItemButton>
        <ListItemIcon>{Icon}</ListItemIcon>
        <ListItemText primary={title} />
      </ListItemButton>
    </ListItem>
  );
};

const Sidebar = ({
  window,
  mobileOpen,
  handleDrawerToggle,
  drawerWidth,
}: IProps) => {
  const container =
    window !== undefined ? () => window().document.body : undefined;

  const handleSignOut = async () => {
    Auth.signOut();
  };

  const drawer = (
    <div>
      <Toolbar />
      <Divider />
      <List>
        <MenuItem
          title={"Disponibilidad"}
          Icon={<CalendarToday />}
          path="availability"
        />
        <MenuItem title={"Reservas"} Icon={<Event />} path="bookings" />
        <MenuItem title={"Cuartos"} Icon={<House />} path="rooms" />
        <MenuItem title={"Usuarios"} Icon={<Person />} path="users" />
      </List>
      <Divider />
      <List>
        <MenuItem
          title={"Cerrar Sesion"}
          Icon={<Logout />}
          onClick={handleSignOut}
        />
      </List>
    </div>
  );

  return (
    <Box
      component="nav"
      sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      aria-label="mailbox folders"
    >
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
          },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
          },
        }}
        open
      >
        {drawer}
      </Drawer>
    </Box>
  );
};

export default Sidebar;
