import React, { useState } from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Switch,
  Typography,
} from "@mui/material";

import RoomType from "../../types/RoomType";
import roomsData from "../../roomsData";
import styled from "@emotion/styled";

interface ItemProps {
  room: RoomType;
  handleReservationClick?: (room: RoomType) => void;
}

const formatOptions = {
  style: "currency",
  currency: "ARS",
  minimumSignificantDigits: 3,
};
const formatter = new Intl.NumberFormat("es-AR", formatOptions);

const MyCard = styled(Card)<{ disabled: boolean }>`
  background-color: ${(props) => (props.disabled ? "lightgray" : "white")};
`;

const Item = ({ room, handleReservationClick }: ItemProps) => {
  const [enabled, setEnabled] = useState<boolean>(true);

  return (
    <MyCard disabled={!enabled}>
      <CardMedia
        sx={{ height: 100 }}
        image="https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/hotel-casa-flora-dormitorio-fresa-1527499512.jpg?crop=1.00xw:0.751xh;0,0.142xh&resize=980:*"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {room.name}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {formatter.format(room.price)} /noche
        </Typography>
      </CardContent>

      <CardActions>
        {handleReservationClick ? (
          <Button size="small" onClick={() => handleReservationClick(room)}>
            Reservar
          </Button>
        ) : (
          <>
            <Button size="small" onClick={() => {}}>
              Editar
            </Button>

            <Switch
              checked={enabled}
              onChange={(event) => setEnabled(event.target.checked)}
            />
          </>
        )}
      </CardActions>
    </MyCard>
  );
};

interface IProps {
  handleReservationClick?: (room: RoomType) => void;
}

const RoomGrid = ({ handleReservationClick }: IProps) => {
  return (
    <Grid container spacing={2} columns={{ xs: 2, md: 12 }}>
      {roomsData.map((room) => {
        return (
          <Grid item xs={4}>
            <Item room={room} handleReservationClick={handleReservationClick} />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default RoomGrid;
